import { FeatureGate } from "#feature-gate/feature-gate.js"
import {
  Avinode,
  Gmail,
  Hubspot,
  Outlook,
  Salesforce,
  Whatsapp,
} from "#pages/integration/components/index.js"

export const Integration = () => {
  return (
    <div className="flex w-full justify-start gap-4 p-4">
      <Avinode />
      <FeatureGate name="Integrations">
        <Hubspot />
      </FeatureGate>
      <FeatureGate name="Integrations">
        <Salesforce />
      </FeatureGate>
      <FeatureGate name="Integrations">
        <Gmail />
      </FeatureGate>
      <FeatureGate name="Integrations">
        <Outlook />
      </FeatureGate>
      <Whatsapp />
    </div>
  )
}
