import InlineSVG from "react-inlinesvg"

import { SeparatorWithChildren } from "@fourel/ui"

import PlaneIcon from "#components/images/plane.svg"
import { formatDate } from "#components/utils/format-date-time.js"
import type {
  RequestedLegType,
  TripType,
} from "#pages/quotations/create-quote/models/trip-types.js"

export const TripSummary = ({ queriedTrip }: { queriedTrip: TripType }) => {
  return queriedTrip?.requestedLegs.map((it: RequestedLegType, i) => (
    <header key={i} className="flex w-full flex-col gap-6">
      <section className="flex w-full items-center gap-7">
        <div className="flex flex-col items-center gap-2 md:flex-row md:items-start">
          <span className="text-xl font-bold md:text-3xl">
            {it.departureAirport.iataCode
              ? it.departureAirport.iataCode
              : it.departureAirport.icaoCode}
          </span>
          <span className="w-20 text-xs text-slate-900 dark:text-slate-400">
            {it.departureDate ? formatDate(it.departureDate) : "No arrival date"}
          </span>
        </div>
        <div className="w-full">
          <SeparatorWithChildren
            children={<InlineSVG src={PlaneIcon} />}
            className="flex-1 bg-slate-500"
          />
        </div>
        <div className="flex flex-col items-center gap-2 md:flex-row md:items-start">
          <span className="text-xl font-bold md:text-3xl">
            {it.arrivalAirport.iataCode
              ? it.arrivalAirport.iataCode
              : it.arrivalAirport.icaoCode}
          </span>
        </div>
      </section>
      <section className="grid w-full grid-cols-2 justify-between gap-4 rounded-xl bg-violet-800/20 p-6 text-sm text-black md:flex md:flex-row">
        <div className="flex flex-col items-center gap-2 md:items-start [&>*]:text-center [&>*]:md:text-start">
          <h3 className="text-muted-foreground">For client:</h3>
          <h3 className="font-semibold">{queriedTrip?.client?.name || ""}</h3>
        </div>
        <div className="flex flex-col items-center gap-2 md:items-start [&>*]:text-center [&>*]:md:text-start">
          <h3 className="text-muted-foreground">Number of passengers:</h3>
          <h3 className="font-semibold">{it.pax}</h3>
        </div>
        <div className="flex flex-col items-center gap-2 md:items-start [&>*]:text-center [&>*]:md:text-start">
          <h3 className="text-muted-foreground">Aircraft preferences:</h3>
          <h3 className="font-semibold">{it.aircraftClass}</h3>
        </div>
        <div className="flex flex-col items-center gap-2 md:items-start [&>*]:text-center [&>*]:md:text-start">
          <h3 className="text-muted-foreground">Special requests:</h3>
          <h3 className="font-semibold">
            {queriedTrip.specialRequests
              ? queriedTrip.specialRequests
              : "No special requests"}
          </h3>
        </div>
      </section>
    </header>
  ))
}
